.p-component {
  font-size: 0.8125rem;
}

.p-inputtext {
  font-size: 0.8125rem;
}

.p-multiselect-filter {
  height: 36px;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  padding: .5rem 1rem;
}

.p-datepicker table td {
  padding: 0;
}

span.p-calendar {
  height: 40px;
  input {
    height: 100%;
  }
  button {
    height: 100%;
  }
}

.p-datepicker-calendar {
  font-size: 0.8125rem;
}
.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.5rem;
}

.p-button {
  padding: 0.5rem 1rem;
}
