.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;

}

.flex-horizontal {
  display: flex;
  align-items: center;
  flex-direction: row;
  &>* {
    flex-shrink: 0;
  }
}

.horizontal-filled>* {
  height: 100%;
}

.flex-vertical {
  display: flex;
  align-items: center;
  flex-direction: column;
  &>* {
    flex-shrink: 0;
  }
}

.vertical-filled>* {
  width: 100%;
}
