.p-paginator {
  display: flex;
  button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: none;
    margin: 0 5px;
    background-color: transparent;
  }
  button:hover {
    background-color: $gray-200;
  }
  button.p-highlight {
    background-color: $blue;
    color: $white;
  }
}
